
:root {
  --lightgrey: #d3d3d3;
  --grey: #ccc;
  --darkergrey: #4b4e52;

  --primaryColor: #332a7c;
  --secondaryColor: #55cba9;
  --tertiaryColor: #1f79c2;
  --primaryAccent:#ff5b43;
  --secondaryAccent: #f4bc00;

  --text: #0D1010;
  --background: #ebf1f7;
}




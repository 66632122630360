/* You can add global styles to this file, and also import other style files */
@import './colors.scss';

html {
    height: 100%;
}

body {

    margin: 0px;
    height: 100%;

    .errorMessage {
        color: var(--primaryAccent);
        font-size: 16px;
    }
    .successMessage {
        color: var(--secondaryColor);
        font-size: 16px;
    }

}



